import { Component, Prop, Vue } from "vue-property-decorator";
import Utils from "@/vue/infrastructure/utils/helpers";

@Component
export default class extends Vue {
  @Prop({ type: String, default: "" })
  pdfUrl!: string | null;

  @Prop({ type: Boolean, default: false })
  fullscreen: boolean;

  get pdfjsUrl(): string {
    const cordova: any = (<any>window).cordova || null;
    const origin: string = cordova ? `${cordova.file.applicationDirectory}www` : `${Utils.getPublicPath()}`;
    const spreadMode: string = Utils.getPDFDesktopSpreadMode() || "2";
    const pdfJsOptions: string = this.mobileView ? `#zoom=page-fit&spreadmode=0&scrollmode=2&externallinktarget=true` : `#zoom=page-fit&spreadmode=${spreadMode}&scrollmode=3&browsemode=1&externallinktarget=true`;
    return this.pdfUrl ? `${origin}/vendor/pdfjs-2.14.305/web/viewer.html?file=${this.pdfUrl}${pdfJsOptions}` : null;
  }

  get mobileView(): boolean {
    const clientWidth: number = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return clientWidth <= 544;
  }

  private onLoad(event: any) {
    console.log(event);
    /*
    const iFrame: any = event.target;
    const link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = "dfCustomViewer.css";
    iFrame.ownerDocument.head.appendChild(link);
     */
  }
}
